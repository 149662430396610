<template>
  <default-layout show-footer>
    <div class="m-4">
      <div class="h-full">
        <div>
          <div class="z-50 filter-group pt-1 sticky top-0 bg-white text-left">
            <!-- <base-input-search v-model="search" class="w-full bg-white text-left" /> -->
            <div>
              <div class="btn-group-filter relative">
                <div
                  class="flex overflow-y-auto text-center justify-around mb-4 border-b border-gray-400"
                >
                  <a
                    class="custom-button cursor-pointer w-1/3 py-1 tab-1"
                    :class="{
                      'active font-bold text-primary': selectedTab === 0,
                    }"
                    style="flex-grow: 50;"
                    @click="changeTab(0)"
                  >
                    <span class="p-1"> Khoản ứng </span>
                  </a>
                  <!-- <a
                    class="custom-button cursor-pointer w-1/3 py-1 tab-2 whitespace-nowrap"
                    :class="{
                      'active font-bold text-primary': selectedTab === 1,
                    }"
                    style="flex-grow: 50;"
                    @click="changeTab(1)"
                  >
                    <span class="p-1"> Khoản vay tiêu dùng </span>
                  </a> -->
                </div>
              </div>
            </div>
          </div>
          <!--          <div class="flex items-center justify-around mx-1.5 mb-4">-->
          <!--            <base-button-->
          <!--              class="bg-gray-300 button-item shadow-none font-bold normal-case px-4 py-2 rounded-xl text-white text-xl w-full mr-4 "-->
          <!--              :class="{ 'bg-primary shadow-2': selectedTab === 0 }"-->
          <!--              @click="changeTab(0)"-->
          <!--            >-->
          <!--              Khoản ứng-->
          <!--            </base-button>-->
          <!--            <base-button-->
          <!--              class="bg-gray-300 button-item shadow-none font-bold normal-case px-4 py-2 rounded-xl text-white text-xl w-full"-->
          <!--              :class="{ 'bg-primary shadow-2': selectedTab === 1 }"-->
          <!--              @click="changeTab(1)"-->
          <!--            >-->
          <!--              Khoản vay tiêu dùng-->
          <!--            </base-button>-->
          <!--          </div>-->
          <salary-advance-item
            v-for="(item, index) in list"
            :key="index"
            :item="item"
            class="mb-4"
          />
        </div>
        <infinite-loading @infinite="infiniteHandler">
          <template #spinner>
            <base-skeleton-loader />
            <base-skeleton-loader />
          </template>
          <template #no-more>
            <div v-if="selectedTab === 0" class="text-center">
              Tất cả khoản ứng đã được hiển thị
            </div>
            <div v-else class="text-center">
              Tất cả khoản ứng đã được hiển thị
            </div>
          </template>
          <template #no-results>
            <div v-if="selectedTab === 0" class="text-center">
              Chưa có khoản ứng nào,
              <router-link
                :to="{ name: constRouter.BORROWER_MONEY.name }"
                class="text-primary font-medium hover:underline"
              >
                Ứng lương ngay
              </router-link>
            </div>
            <div v-else class="text-center">
              Chưa có khoản vay nào,
              <router-link
                :to="{ name: constRouter.BORROWER_MONEY.name }"
                class="text-primary font-medium hover:underline"
              >
                Đăng ký ngay
              </router-link>
            </div>
          </template>
        </infinite-loading>
      </div>
    </div>
  </default-layout>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import ConstRouter from '@/constants/constRouter';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import InfiniteLoading from 'infinite-loading-vue3-ts';
import { list, listGen } from '@/apis/ApiBorrower';
import BaseSkeletonLoader from '@/components/Base/BaseSkeletonLoader.vue';
import constRouter from '@/constants/constRouter';
import SalaryAdvanceItem from '../Item/SalaryAdvanceItem.vue';
import { LoanList } from '@/views/Dashboard/types';

export default defineComponent({
  name: 'SalaryAdvanceList',
  components: {
    BaseSkeletonLoader,
    DefaultLayout,
    SalaryAdvanceItem,
    InfiniteLoading,
  },
  setup() {
    const router = useRouter();
    const list = ref<LoanList[]>([]);

    const handleClickItem = () => {
      router.push({
        name: ConstRouter.INTRO_INPUT_NAME.name,
      });
    };

    return {
      list,
      handleClickItem,
    };
  },
  data: () => ({
    constRouter,
    page: 1,
    state: null as any, //state infinity load
    startDate: null,
    endDate: null,
    selectedTab: 0,
  }),
  computed: {
    searchContTract(): any {
      const { startDate, endDate } = this;
      return {
        startDate,
        endDate,
      };
    },
  },
  watch: {
    searchContTract() {
      this.page = 1;
      this.list = [];
      this.state?.reset();
    },
  },
  methods: {
    cancelSearch() {
      this.startDate = null;
      this.endDate = null;
    },
    changeTab(tab: number) {
      if (tab != this.selectedTab) {
        this.selectedTab = tab;

        this.page = 1;
        this.list = [];
        this.state?.reset();
      }
    },
    infiniteHandler($state: any): void {
      if (!this.state) {
        this.state = $state;
      }
      const { page, startDate, endDate } = this;
      if (this.selectedTab === 0) {
        list({ page, startDate, endDate })
          .then(({ data }) => {
            if (data.data?.length) {
              this.page += 1;
              this.list.push(...data.data);

              $state.loaded();
            } else {
              $state.complete();
            }
          })
          .catch(() => {
            $state.complete();
          });
      } else {
        listGen({ page, startDate, endDate })
          .then(({ data }) => {
            if (data.data?.length) {
              this.page += 1;
              this.list.push(...data.data);

              $state.loaded();
            } else {
              $state.complete();
            }
          })
          .catch(() => {
            $state.complete();
          });
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@media (max-width: 340px) {
  .filter-date-wrapper {
    flex-direction: column;

    &__button {
      margin-top: 8px;
      padding-left: 0;
    }
  }
}
// .filter-group {
//   .active {
//     border-bottom: 2px solid #5dad72;
//   }
// }
</style>
