
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import ConstRouter from '@/constants/constRouter';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import InfiniteLoading from 'infinite-loading-vue3-ts';
import { list, listGen } from '@/apis/ApiBorrower';
import BaseSkeletonLoader from '@/components/Base/BaseSkeletonLoader.vue';
import constRouter from '@/constants/constRouter';
import SalaryAdvanceItem from '../Item/SalaryAdvanceItem.vue';
import { LoanList } from '@/views/Dashboard/types';

export default defineComponent({
  name: 'SalaryAdvanceList',
  components: {
    BaseSkeletonLoader,
    DefaultLayout,
    SalaryAdvanceItem,
    InfiniteLoading,
  },
  setup() {
    const router = useRouter();
    const list = ref<LoanList[]>([]);

    const handleClickItem = () => {
      router.push({
        name: ConstRouter.INTRO_INPUT_NAME.name,
      });
    };

    return {
      list,
      handleClickItem,
    };
  },
  data: () => ({
    constRouter,
    page: 1,
    state: null as any, //state infinity load
    startDate: null,
    endDate: null,
    selectedTab: 0,
  }),
  computed: {
    searchContTract(): any {
      const { startDate, endDate } = this;
      return {
        startDate,
        endDate,
      };
    },
  },
  watch: {
    searchContTract() {
      this.page = 1;
      this.list = [];
      this.state?.reset();
    },
  },
  methods: {
    cancelSearch() {
      this.startDate = null;
      this.endDate = null;
    },
    changeTab(tab: number) {
      if (tab != this.selectedTab) {
        this.selectedTab = tab;

        this.page = 1;
        this.list = [];
        this.state?.reset();
      }
    },
    infiniteHandler($state: any): void {
      if (!this.state) {
        this.state = $state;
      }
      const { page, startDate, endDate } = this;
      if (this.selectedTab === 0) {
        list({ page, startDate, endDate })
          .then(({ data }) => {
            if (data.data?.length) {
              this.page += 1;
              this.list.push(...data.data);

              $state.loaded();
            } else {
              $state.complete();
            }
          })
          .catch(() => {
            $state.complete();
          });
      } else {
        listGen({ page, startDate, endDate })
          .then(({ data }) => {
            if (data.data?.length) {
              this.page += 1;
              this.list.push(...data.data);

              $state.loaded();
            } else {
              $state.complete();
            }
          })
          .catch(() => {
            $state.complete();
          });
      }
    },
  },
});
